<template>
  <div>
    <vx-card>
       <vs-row vs-type="flex" vs-justify="space-between" >
          <vs-col vs-align="center" vs-w="3"  style="padding-right: 4px ">
              <label class="vs-input--label">Marca</label>
              <v-select                                               
                :options="CONSTANTE_MARCA_MODELOS_MODULOS" 
                v-model="filtro.marca"                  
                :reduce="r => r.data"                    
                @input="alterarModelo"                              
                class="w-full vs-input--label">
              </v-select> 
          </vs-col>
          <vs-col vs-align="center" vs-w="3"  style="padding-right: 4px ">
              <label class="vs-input--label mr-2">Modelo</label>          
              <v-select                                               
                  :options="getModeloModulo" 
                  v-model="filtro.tipoModulo"                              
                  :reduce="r => r.data"                    
                  class="w-full vs-input--label">
              </v-select> 
          </vs-col>
          <vs-col vs-align="center" vs-w="3"  style="padding-right: 4px ">
              <label class="vs-input--label">Codigo</label>
              <vs-input class="w-full" v-model="filtro.codigo"  @keyup.enter="fetchLista(filtro)"/>    
          </vs-col>         
          <vs-col vs-align="center" vs-w="3">                     
              <label class="vs-input--label mr-2">Placa</label>
              <vs-input v-model="filtro.placa" class="w-full" v-mask="['AAA-#X##', 'AAA-#X##']" icon="calendar_view_day" @keyup.enter="fetchLista(filtro)" />
          </vs-col>
      </vs-row>
      <vs-row vs-type="flex" vs-justify="space-between" class="mt-2">
        <vs-col vs-align="center" vs-w="3" style="padding-right: 4px ">
              <label class="vs-input--label">Data Ativação</label>
              <flat-pickr v-model="filtro.dataAtivacao" :config="configDatePicker"/>      
        </vs-col>                  
        <vs-col vs-align="center" vs-w="3"  style="padding-right: 4px ">
          <label class="vs-input--label">Serial</label>
          <vs-input class="w-full" v-model="filtro.serial" @keyup.enter="fetchLista(filtro)"/>
        </vs-col>
        <vs-col vs-align="center" vs-w="3"  style="padding-right: 4px ">
          <label class="vs-input--label">Número GSM</label>
          <vs-input class="w-full" v-model="filtro.numeroGSM" @keyup.enter="fetchLista(filtro)"/>
        </vs-col>
       
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="3">
          <vs-button class="mr-2" color="primary" type="filled" icon-pack="feather" icon="icon-search" @click="fetchLista(filtro)"></vs-button>
          <vs-button class="mr-2" color="dark" type="border" icon-pack="feather" icon="icon-file" @click="limparFiltros"></vs-button>            
          <vs-button color="rgb(11, 189, 135)" type="filled" icon-pack="feather" icon="icon-save" @click="exportarParaCSV"></vs-button>          
        </vs-col>
      </vs-row>
      <div class="flex w-full mt-4">
      <p class="text-success text-sm"> Ativação módulo principal </p>
      <p class="text-warning text-sm ml-4"> Ativação módulo Backup </p>
      </div>
      
    </vx-card>

    <p class="text-danger mt-2 text-sm"> {{dadosDaGrid.length }} registros </p>
     
    <ag-grid-vue
      ref="agGridTable"           
      :gridOptions="configuracaoGrid.gridOptions"
      class="ag-theme-material ag-grid-table ag-grid-altura mt-1"
      :columnDefs="configuracaoGrid.columnDefs"
      :defaultColDef="configuracaoGrid.defaultColDef"
      :rowData="dadosDaGrid"
      :rowHeight="20"
      :headerHeight="25"        
      rowSelection="single"
      colResizeDefault="shift"
      @row-selected="onRowSelectedRegistro"    
      :animateRows="true"
      :floatingFilter="false"
      :pagination="false"
      :paginationAutoPageSize="false"
      :suppressPaginationPanel="true"
      >
    </ag-grid-vue>      
    
  </div>

</template>

<script>
import axios from "@/axios.js"
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { AgGridVue } from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import {Portuguese as PortugueseLocale} from 'flatpickr/dist/l10n/pt.js';
import { CONSTANTE_MARCAS_MODULO } from '@/constantesComboBox.js'
import { CONSTANTE_MARCA_MODELOS_MODULOS } from '@/constantesComboBox.js'

const URL_SERVLET = "/RelatorioModulos";

export default {
  components: {
    AgGridVue,
    flatPickr
  },
  data() {
    return {
      configDatePicker: {          
            altInput: true,
            altFormat: "d-m-Y",
            dateFormat: "U",             
            altInputClass: "vs-inputx vs-input--input normal hasValue text-center",
            locale: PortugueseLocale
      },
      filtro : {codigo: null, serial:  null, numeroGSM: null, tipoModulo: null, marca: null, dataAtivacao: null, placa: null},
      configuracaoGrid: { 
          gridApi: null, gridOptions: {}, 
          defaultColDef: { sortable: true, resizable: true, suppressMenu: true  },
          columnDefs: [        
            { headerName: 'Cliente', field: 'nomeCliente' , width: 250},           
            { headerName: 'Veículo', field: 'placa', width: 80},           
            { headerName: 'Código', field: 'codigo' , width: 120,
              cellClass: function(params) { return (params.data.tipoInstalacao ==='B'?'text-warning':'text-success'); }
            },           
            { headerName: 'Marca', field: 'marca', width: 130 },           
            { headerName: 'Serial', field: 'serial', width: 120 },           
            { headerName: 'Modelo', field: 'tipo', width: 130 },           
            { headerName: 'Número GSM', field: 'numeroGSM', width: 100 },           
            { headerName: 'Operadora', field: 'operadoraGSM', width: 80 },  
            { headerName: 'Ativação', field: 'dataAtivacao', width: 100,
              cellClass: function(params) { return (params.data.tipoInstalacao ==='B'?'text-warning':'text-success'); }},           
            { headerName: 'Desativação', field: 'dataDesativacao', width: 100,
              cellClass: function(params) { return (params.data.tipoInstalacao ==='B'?'text-warning':'text-success'); }}                       
          ]
      },
      dadosDaGrid: [],
      totalRegistros: 0,
      listaClientes : [],     
    }
  }, 
  computed:{
    getModeloModulo() {
        if (this.filtro.marca) {
            let marca =  this.CONSTANTE_MARCA_MODELOS_MODULOS.find( ({data}) => data === this.filtro.marca);
            if (marca) {
                return marca.modelos;
            }
        }

        return [];
    }
  },
  created() {
      this.CONSTANTE_MARCAS_MODULO = CONSTANTE_MARCAS_MODULO;
      this.CONSTANTE_MARCA_MODELOS_MODULOS = CONSTANTE_MARCA_MODELOS_MODULOS;
  },
  methods: {
    alterarModelo() {
        this.filtro.tipoModulo = null;
    }, 
    limparFiltros() {
      this.filtro = {codigo: null, serial:  null, numeroGSM: null, tipoModulo: null, marca: null, dataAtivacao: null, placa: null};      
    },
    fetchLista(pFiltro) {     
      this.$vs.loading() 
      const params = new URLSearchParams();
      params.append('method', 'FindAll');
      params.append('orderDirection', 'ASC');
      params.append('outputFormat', 'JSON');
      
      if (pFiltro.codigo) {
        params.append('codigo', pFiltro.codigo);
      }

      if (pFiltro.marca) {
        params.append('marca', pFiltro.marca);
      }

      if (pFiltro.tipoModulo) {
        params.append('tipo', pFiltro.tipoModulo);
      }

      if (pFiltro.serial) {
        params.append('serial', pFiltro.serial);
      }

      if (pFiltro.numeroGSM) {
        params.append('numeroGSM', pFiltro.numeroGSM);
      }

      if (pFiltro.dataAtivacao) {
        params.append('dataAtivacao', pFiltro.dataAtivacao*1000);
      }

       if (pFiltro.placa) {
        params.append('placa', pFiltro.placa);
      }

      axios.post(URL_SERVLET, params, {              
              credentials: 'include',
              responseEncoding: 'iso88591',
              withCredentials: true } )
      .then((response) => {
          this.$vs.loading.close();
          if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
              this.$router.push({ path: '/' });
          } else {
              this.dadosDaGrid = response.data;         
              this.totalRegistros = this.dadosDaGrid.length;                              
          }
      })
      .catch((error) => { 
          this.$vs.loading.close();
          /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchListaPoI - " + error); 
      })    
                        
    },
    
    exportarParaCSV() {
      var params = {columnSeparator: ';', fileName: 'RelatorioModulos.csv'};
      this.configuracaoGrid.gridApi.exportDataAsCsv(params);
    },    
    
  },
  mounted() {
    this.configuracaoGrid.gridApi = this.configuracaoGrid.gridOptions.api;
    this.configuracaoGrid.gridApi.sizeColumnsToFit();        
    
  },
  beforeDestroy() {    
      
  }
}

</script>
<style lang="scss" scoped>
  .ag-grid-altura{
    height: calc(100vh - 250px);
  }
</style>